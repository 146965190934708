<template>
  <!-- 首页 -->
  <div class="home">
    <div class="navigation" v-if="fullWidth > 1024">
      <div class="navigation-model">
        <div class="navigation-model-content">
          <div class="navigation-icon">
            <img src="@/assets/img/logos.png" alt />
          </div>
          <div class="navigation-line"></div>
          <div class="navigation-menu">
            <div
              class="menu-n"
              @click="toPath('/home/course')"
              :class="{menus:actData=='course'}"
            >
              <div class="menu-n-icon">
                <img src="@/assets/img/home1.png" alt />
              </div>
              <div class="menu-n-name">我的课程</div>
            </div>

            <div
              class="menu-n"
              @click="toPath('/home/questionBank')"
              :class="{menus:actData=='questionBank'}"
            >
              <div class="menu-n-icon">
                <img src="@/assets/img/home3.png" alt />
              </div>
              <div class="menu-n-name">我的题库</div>
            </div>

            <div
              class="menu-n"
              @click="toPath('/home/wrongTopic')"
              :class="{menus:actData=='wrongTopic'}"
            >
              <div class="menu-n-icon">
                <img src="@/assets/img/home2.png" alt />
              </div>
              <div class="menu-n-name">我的错题</div>
            </div>
          </div>
        </div>
        <div class="personal">
          <div class="personal-icon">
            <img src="@/assets/img/user.png" alt />
          </div>

          <el-popover placement="bottom" trigger="click">
            <div class="extit">
              <div class="extit-img">
                <img src="@/assets/img/gj.png" alt />
              </div>
              <div @click="exit('/login')">退出登录</div>
            </div>
            <el-button class="icon-name" type="text" slot="reference">退出登录</el-button>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="navigation" v-else>
<!--      <div class="navigation-icon1" @click="drawer = true">-->
<!--        <i class="el-icon-s-home"></i>-->
<!--        <img src="@/assets/img/home3.png" alt />-->
<!--      </div>-->
      <div class="navigation-icon2">
        <img src="@/assets/img/logos.png" alt />
      </div>
      <div class="navigation-icon1" @click="drawer = true">
        <i class="el-icon-user-solid"></i>
<!--        <img src="@/assets/img/user.png" alt />-->
      </div>
      <el-drawer
        title="菜单"
        :visible.sync="drawer"
        direction="ltr"
        size="60%">
        <div class="drawer-icon">
          <img src="@/assets/img/user.png" alt />
        </div>
        <div class="drawer-user">
          <span>用户1</span>
        </div>
        <div class="navigation-menu">
          <div class="menu-n" @click="toPath('/home/course')" :class="{menus:actData=='course'}">
            <div class="flex">
              <div class="menu-n-icon">
                <img src="@/assets/img/home1.png" alt />
              </div>
              <div class="menu-n-name">我的课程</div>
            </div>
          </div>

          <div class="menu-n" @click="toPath('/home/questionBank')" :class="{menus:actData=='questionBank'}">
            <div class="flex">
              <div class="menu-n-icon">
                <img src="@/assets/img/home3.png" alt />
              </div>
              <div class="menu-n-name">我的题库</div>
            </div>
          </div>

          <div class="menu-n" @click="toPath('/home/wrongTopic')" :class="{menus:actData=='wrongTopic'}">
            <div class="flex">
              <div class="menu-n-icon">
                <img src="@/assets/img/home2.png" alt />
              </div>
              <div class="menu-n-name">我的错题</div>
            </div>
          </div>
          <div class="menu-n" @click="exit('/login')">
            <div class="flex">
              <div class="menu-n-icon">
                <img src="@/assets/img/home2.png" alt />
              </div>
              <div class="menu-n-name">退出登录</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>

    <div class="content">
      <router-view />
    </div>
    <div class="footer" v-if="fullWidth <= 1024">
      <div class="foot-page" @click="toPath('/home/course')" :class="{selected:actData=='course'}">
        <div class="menu-n-icon">
          <img src="@/assets/img/home1.png" alt />
        </div>
        <div class="menu-n-name">我的课程</div>
      </div>
      <div class="foot-page" @click="toPath('/home/questionBank')" :class="{selected:actData=='questionBank'}">
        <div class="menu-n-icon">
          <img src="@/assets/img/home3.png" alt />
        </div>
        <div class="menu-n-name">我的题库</div>
      </div>
      <div class="foot-page" @click="toPath('/home/wrongTopic')" :class="{selected:actData=='wrongTopic'}">
        <div class="menu-n-icon">
          <img src="@/assets/img/home2.png" alt />
        </div>
        <div class="menu-n-name">我的错题</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
    this.fullWidth = document.documentElement.clientWidth
    this.$store.dispatch('saveWidth',this.fullWidth)
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {
    //
    this.actData = this.$router.history.current.fullPath.split("/")[2];
    window.onresize = () => {
      this.fullWidth = document.documentElement.clientWidth
      this.$store.dispatch('saveWidth',this.fullWidth)
    }
  },

  data() {
    //数据

    return {
      actData: "course",
      fullWidth:document.documentElement.clientWidth,
      drawer: false,
    };
  },
  watch: {
    $route(to) {
      this.actData = to.path.split("/")[2];
    },
  },
  methods: {
    //方法
    toPath(path) {
      // localStorage.setItem("my_token",'');
      // localStorage.setItem("valid_end_time",'');
      this.$router
        .push(path)
        .then(() => {})
        .catch(err => err);
      this.drawer = false
    },
    exit(path) {
      this.$confirm("确认关闭？")
        .then(() => {
          localStorage.setItem("my_token", "");
          localStorage.setItem("valid_end_time", "");
          this.$router
            .push(path)
            .then(() => {})
            .catch(err => err);
          this.drawer = false
        })
        .catch(() => {});
    },
  }
};
</script>

<style lang="scss" scoped>
img {
  height: 100%;
  width: 100%;
}
.home {
  width: 100%;
  height: 56px;
  background: #00203d;
  opacity: 1;
}
.navigation {
  width: 100%;
  height: 56px;
  background: #00203d;
  margin: auto;
}

.navigation-model {
  height: 56px;
  width: 67%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: black; */
}

.navigation-icon {
  width: 110px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  min-height: 100px;
}
.navigation-model-content {
  display: flex;
  align-items: center;
}
.navigation-line {
  height: 19px;
  width: 1px;
  opacity: 0.7;
  background: #98a4b2;
  margin: 0 39px 0 30px;
}
.navigation-menu {
  display: flex;
  align-items: center;
}
.menu-n {
  display: flex;
  align-items: center;
  margin-right: 37px;
  opacity: 0.7;
  cursor: pointer;
}
.menu-n:hover{
  opacity: 1;
}

.menus {
  display: flex;
  align-items: center;
  margin-right: 37px;
  opacity: 1;
  cursor: pointer;
}
.menus:hover{
  opacity: 1;
}

.menu-n-icon {
  width: 17px;
  display: flex;
  margin-right: 6px;
  /*margin-top: 5px;*/
  img {
    width: 100%;
    height: 100%;
  }
}
.menu-n-name {
  font-size: 14px;
  color: white;
}
.personal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.personal-icon {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 4px;
}
.icon-name {
  margin-bottom: 15px;
  font-size: 14px;
  color: #98a9ba;
}

.extit-img {
  width: 15px;
  height: 15px;

  margin: 0 10px;
}

.extit {
  text-align: center;
  align-items: center;
  border-left: 2px solid white;
  cursor: pointer;
  display: flex;
}
.extit:hover {
  transition: 0.25s;
  border-left: 2px solid #0080ff;
}
@media screen and (max-width: 1024px) {
  .navigation {
    height: 56px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: black; */
  }

  .navigation-icon1 {
    margin: 0 10px;
    color: #0c7aff;
    font-size: 26px;
    img {
      width: 26px;
      height: 26px;
    }
  }
  .navigation-icon2 {
    width: calc(100% - 54px);
    height: 40px;
    text-align: center;
    img {
      margin-left: 54px;
      width: 110px;
      height: 40px;
    }
  }
  ::v-deep .el-drawer{
    background-color: #00203d;
  }
  ::v-deep .el-drawer__header{
    display: none;
  }
  .drawer-icon{
    margin: 0 auto;
    margin-top: 50px;
    width: 100px;
    height: 100px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .drawer-user{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 50px;
    opacity: 0.7;
  }
  .navigation-menu {
    display: block;
  }
  .menu-n {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    cursor: pointer;

  }

  .menu-n:hover{
    opacity: 1;
  }

  .menus {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    opacity: 1;
    cursor: pointer;
    background-color: #0d80ff;
  }
  .menus:hover{
    opacity: 1;
  }
  .flex{
    width: 50%;
    display: flex;
    align-items: center;
  }
  .menu-n-icon {
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .menu-n-name {
    font-size: 16px;
    color: white;
  }
  .content{
    padding-bottom: 56px;
  }
  .footer{
    width: 100%;
    height: 56px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    .foot-page{
      width: calc((100% - 2px) / 3);
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00203d;
      opacity: 1;
    }
    .foot-page:nth-child(2){
      border-left: 1px solid #98a9ba;
      border-right: 1px solid #98a9ba;
    }
    .selected{
      opacity: 0.7;
    }
  }
}
</style>
